import { useLocalStorage } from '@mantine/hooks';
import { Branch, BusinessCategory, EstimateState, JobCategories, JobLogType, JobPhase, PurchaseOrderStatus, ScheduledJobType } from '@prisma/client';
import { UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query';
import { titlifyEnum } from '@utils/stringHelpers';
import { RouterOutput, trpc } from '@utils/trpc';
import { ContextUseState, isNotNullish } from '@utils/types';
import { useRouter } from 'next/router';
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FAIcon } from '~/components/utils/FAIcons';
import { DropDownOption } from '~/components/utils/UtilityProps';
import useInfinite from '~/hooks/useInfinite';
import { ConsequentialJobLogTypes, FormattedJob, JobSubView } from '~/server/schema/job.schema';
import { UserOption } from '~/server/schema/user.schema';
import { useUserContext } from './UserContext';

//Job Table Variables / Methods
export const jobListColumns = [
	{
		name: 'Customer',
		mobile: true,
	},
	{
		name: 'Job',
		mobile: true,
	},
	{
		name: 'Assignee',
		mobile: false,
		centered: true,
	},
	// {
	// 	name: 'Phase',
	// 	mobile: false,
	// 	centered: true,
	// },
	{
		name: 'Job Address',
		mobile: true,
	},
	{
		name: 'Job Type',
		mobile: false,
		centered: true,
	},
	{
		name: 'Estimated Total',
		mobile: false,
		centered: true,
	},
	{
		name: 'Status',
		mobile: true,
		centered: true,
	},
	{
		name: 'Actions',
		mobile: true,
	},
];

const categoryToDropdown = (category: JobCategories) => ({
	value: category,
	text: titlifyEnum(category),
	active: false,
});

export const jobTypes: Array<DropDownOption> = Object.values(JobCategories).map(categoryToDropdown);

const jobLogToDropDown = (logEntry: JobLogType) => ({
	value: logEntry,
	text: titlifyEnum(logEntry),
	active: false,
});

export const consequentialJobLogTypeOpts: Array<DropDownOption> = ConsequentialJobLogTypes.map(jobLogToDropDown);

// export const searchOptions: Array<DropDownOption> = [{ value: 'global', text: 'Global', active: true }, { value: 'focused', text: 'Focused', active: false }];
export const views = [
	{
		label: 'list',
		icon: <FAIcon icon="list" className="mr-1" />,
	},
	{
		label: 'board',
		icon: <FAIcon icon="objects-align-top" className="mr-1" />,
	},
];

export enum FilterType {
	TYPES = 't',
	ASSIGNEES = 'a',
	LOG_STATUSES = 'l',
	PHASE = 'p',
}

export type JobIndexContextSchema = {
	businessCategory?: BusinessCategory;
	jobCountBySubView: Record<string, number> | undefined;
	customStages: RouterOutput['stages']['listCustomStagesWithJobCount'];
	customStageWarningMap: Map<number, number>;
	filterBarContext: {
		view: string;
		setView: (view: string) => void;
		jobSubView: JobSubView;
		stageId: number | undefined;
		filters: {
			types: string[];
			assignees: string[];
			activePhases: string[];
			logStatuses: string[];
			anyLogTypes: string[];
			purchaseOrderStates: string[];
			somePurchaseOrderStates: string[];
			notAllPurchaseOrderStates: string[];
			futureScheduledJobTypes: string[];
			disallowedScheduledJobTypes: string[];
			estimateStates: string[];
			estimateRequiresBalance: boolean;
			requireBillingApproaching: boolean;
			requireProblemReason: boolean;
		};
		updateFilters: (queryName: FilterType) => (activeFilters: string[]) => void;
		clearFilters: () => void;
		sortBy: string;
		sortDirection: 'asc' | 'desc';
		updateSortBy: (updatedSortBy: string) => void;
		categorySearch: ContextUseState<string>;
		globalJobSearch: ContextUseState<string>;
		userOpts: UserOption[];
		branches?: Branch[];
		activeBranches: string[];
		setActiveBranches: Dispatch<SetStateAction<string[]>>;
		shouldHideStatusFilter?: boolean;
	};
	shouldHideCustomStageTabs: boolean;
	tableContext: {
		categorySearch: ContextUseState<string>;
		jobs: [BusinessCategory, FormattedJob[]][];
		fetchNextPage: () => void;
		isFetching: boolean;
		activeSearch: boolean;
		jobQueryMeta?: UseInfiniteQueryResult<{ nextCursor?: number; items: unknown[] } | undefined>;
		// topAnalyticsData:
		// topAnalyticsMeta: topAnalyticsQuery,
	};
};

export const useJobIndexContext = () => {
	const context = useContext(JobIndexContext);
	if (context == null) throw new Error('JobIndexContext: not ready');
	return context;
};

const translatedSubView = {
	[JobSubView.ALL_OPPORTUNITIES]: JobPhase.ESTIMATE,
	[JobSubView.ALL_WON_JOBS]: JobPhase.WORK_ORDER,
	[JobSubView.CLOSED]: JobPhase.JOB_LOST,
	[JobSubView.FLAGGED]: 'FLAGGED',
};

export const JobIndexContext = createContext<JobIndexContextSchema | null>(null);

export const JobIndexContextProvider = ({ children }: PropsWithChildren<object>) => {
	const router = useRouter();
	const { businessCategorySummary } = useUserContext();

	//Filter Bar States & tRPC Queries
	const routerSubView = router.query.subView as string | undefined;
	const jobSubView = (routerSubView?.split('?')[0]?.toUpperCase() ?? JobSubView.ALL) as JobSubView;

	const stageId = +((router.query.stageId as string | undefined) ?? '');

	const routerCategory = router.query.businessCategory as string | undefined;

	let activeCategory: BusinessCategory | undefined;

	if (routerCategory === undefined) {
		activeCategory = undefined;
	} else {
		activeCategory =
			(routerCategory?.split('?')[0]?.toUpperCase() as BusinessCategory) ?? businessCategorySummary[0]?.businessCategory ?? 'RESIDENTIAL';
	}

	const customStagesQuery = trpc.stages.listCustomStagesWithJobCount.useQuery({
		businessCategory: activeCategory,
		jobPhase: translatedSubView[jobSubView],
	});
	const customStages = customStagesQuery.data ?? [];
	const customStageWarningMap = useMemo(() => {
		return new Map(
			customStagesQuery.data
				?.filter((stage) => stage.daysInStageUntilWarning !== null)
				?.map((stage) => [stage.id, stage.daysInStageUntilWarning!])
		);
	}, [customStagesQuery.data]);

	const urlSearch = router.query.search as string | undefined;
	const [globalJobSearch, setGlobalJobSearch] = useState<string>('');
	const [categoryJobSearch, setCategoryJobSearch] = useState<string>(urlSearch ?? globalJobSearch ?? '');

	useEffect(() => setCategoryJobSearch(urlSearch ?? ''), [urlSearch]);

	const [activePhases, setActivePhases] = useLocalStorage<string[]>({
		key: 'jobIndex.phasesFilter',
		defaultValue: [],
	});

	useEffect(() => {
		if (jobSubView === JobSubView.CLOSED) {
			setActivePhases(['closed']);
		} else if (jobSubView === JobSubView.ALL_WON_JOBS) {
			setActivePhases(['workOrder']);
		} else if (jobSubView === JobSubView.FLAGGED) {
			setActivePhases(['flagged']);
		} else if (jobSubView === JobSubView.ALL_OPPORTUNITIES) {
			setActivePhases(['estimate']);
		} else if (jobSubView === JobSubView.ALL || isNotNullish(stageId)) {
			// NB: We're filtering on either phase or stage
			setActivePhases(['all']);
		} else {
			setActivePhases(['estimate']);
		}
	}, [jobSubView, setActivePhases]);

	const [selectedTypeFilters, setSelectedTypeFilters] = useLocalStorage<string[]>({ key: 'jobIndex.typeFilter', defaultValue: [] });
	const [selectedAssignees, setSelectedAssignees] = useLocalStorage<string[]>({ key: 'jobIndex.assigneesFilter', defaultValue: [] });
	const [logStatuses, setLogStatuses] = useLocalStorage<string[]>({ key: 'jobIndex.logStatusesFilter', defaultValue: [] });
	// const [activeSearchOption, setActiveSearchOption] = useLocalStorage<string[]>({ key: 'jobIndex.activeSearchOption', defaultValue: [] });
	const [sortBy, setSortBy] = useLocalStorage<string>({ key: 'jobIndex.sortBy', defaultValue: 'Customer' });
	const [sortDirection, setSortDirection] = useLocalStorage<'asc' | 'desc'>({ key: 'jobIndex.sortDirection', defaultValue: 'desc' });

	const view = router.pathname.includes('board') ? 'board' : 'list';
	const selectedRequireEstimateBalance = false;
	let selectedRequiredBillingApproaching = false;
	const [selectedRequiredProblemReason, setSelectedRequiredProblemReason] = useState(false);
	const selectedLogStatuses: JobLogType[] = [];
	const selectedAnyLogStatuses: JobLogType[] = [];
	const selectedRequiredEstimateStates: EstimateState[] = [];
	const selectedRequiredPurchaseOrderStates: PurchaseOrderStatus[] = [];
	const selectedSomePurchaseOrderStates: PurchaseOrderStatus[] = [];
	const selectedNotAllPurchaseOrderStates: PurchaseOrderStatus[] = [];
	const selectedDisallowedScheduledJobTypes: ScheduledJobType[] = [];
	const selectedFutureScheduledJobTypes: ScheduledJobType[] = [];

	const [selectedPhases, setSelectedPhases] = useState<string[]>([]);

	useEffect(() => {
		const currentSelectedPhases: string[] = [];

		if (activePhases.includes('estimate')) currentSelectedPhases.push(JobPhase.ESTIMATE);

		if (activePhases.includes('all')) currentSelectedPhases.push(JobPhase.ESTIMATE, JobPhase.WORK_ORDER);

		if (activePhases.includes('workOrder')) currentSelectedPhases.push(JobPhase.WORK_ORDER);

		if (activePhases.includes('flagged')) {
			currentSelectedPhases.push(JobPhase.ESTIMATE, JobPhase.WORK_ORDER, JobPhase.JOB_COMPLETE, JobPhase.JOB_LOST);
			setSelectedRequiredProblemReason(true);
		} else {
			setSelectedRequiredProblemReason(false);
		}

		if (activePhases.includes('closed')) currentSelectedPhases.push(JobPhase.JOB_COMPLETE, JobPhase.JOB_LOST);

		// If no active phases are selected, fetch estimates and work orders without closed jobs by default
		if (activePhases.length === 0 || currentSelectedPhases.length === 0) currentSelectedPhases.push(JobPhase.ESTIMATE, JobPhase.WORK_ORDER);

		setSelectedPhases(currentSelectedPhases);
	}, [activePhases, setSelectedPhases, setSelectedRequiredProblemReason]);

	const updateFilters = useCallback(
		(queryName: FilterType) => (activeFilters: string[]) => {
			switch (queryName) {
				case FilterType.ASSIGNEES:
					setSelectedAssignees(activeFilters);
					break;
				case FilterType.LOG_STATUSES:
					setLogStatuses(activeFilters);
					break;
				case FilterType.TYPES:
					setSelectedTypeFilters(activeFilters);
					break;
				case FilterType.PHASE:
					setActivePhases(activeFilters);
					break;
			}
		},
		[setSelectedAssignees, setLogStatuses, setSelectedTypeFilters, setActivePhases]
	);

	const clearFilters = () => {
		setSelectedAssignees([]);
		setLogStatuses([]);
		setSelectedTypeFilters([]);
	};

	const updateSortBy = useCallback(
		(updatedSortBy: string) => {
			if (updatedSortBy === sortBy) {
				setSortDirection((currentSortDirection) => (currentSortDirection === 'desc' ? 'asc' : 'desc'));
			} else {
				setSortBy(updatedSortBy);

				if (updatedSortBy === 'Customer') {
					setSortDirection('asc');
				} else {
					setSortDirection('desc');
				}
			}
		},
		[setSortBy, setSortDirection, sortBy]
	);

	const setView = useCallback(
		(view: string) => {
			const query = router.query;
			let pathname = '/';
			if (view == 'board') {
				pathname = '/board';
			}
			router.push({ pathname, query });
		},
		[router]
	);

	//tRPC
	const userOptsQuery = trpc.users.getBusinessUserOpts.useQuery(undefined, {
		enabled: !!selectedAssignees,
		//these methods prevent the FE from clearing the active items in the dropdown.
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
	});

	const { data: branches } = trpc.branch.readAll.useQuery();
	const [activeBranches, setActiveBranches] = useState<string[]>([]);

	const FilterBarContent: JobIndexContextSchema['filterBarContext'] = {
		view,
		setView,
		jobSubView,
		stageId,
		filters: {
			types: selectedTypeFilters,
			assignees: selectedAssignees,
			activePhases,
			logStatuses: selectedLogStatuses.length ? selectedLogStatuses : logStatuses,
			purchaseOrderStates: selectedRequiredPurchaseOrderStates,
			somePurchaseOrderStates: selectedSomePurchaseOrderStates,
			notAllPurchaseOrderStates: selectedNotAllPurchaseOrderStates,
			futureScheduledJobTypes: selectedFutureScheduledJobTypes,
			disallowedScheduledJobTypes: selectedDisallowedScheduledJobTypes,
			estimateStates: selectedRequiredEstimateStates,
			anyLogTypes: selectedAnyLogStatuses,
			estimateRequiresBalance: selectedRequireEstimateBalance,
			requireBillingApproaching: selectedRequiredBillingApproaching,
			requireProblemReason: selectedRequiredProblemReason,
		},
		updateFilters,
		clearFilters,
		sortBy,
		sortDirection,
		updateSortBy,
		categorySearch: [categoryJobSearch, setCategoryJobSearch],
		globalJobSearch: [globalJobSearch, setGlobalJobSearch],
		userOpts: userOptsQuery.data ? userOptsQuery.data : [],
		branches: branches ?? undefined,
		activeBranches,
		setActiveBranches,
		shouldHideStatusFilter: !!selectedLogStatuses.length,
	};

	//Table States & tRPC Queries
	const {
		query: allJobsQuery,
		data,
		fetchNextPage,
	} = useInfinite('jobs', 'allForFrontPage', {
		filters:
			selectedPhases.length > 0 ||
			selectedTypeFilters.length > 0 ||
			selectedAssignees.length > 0 ||
			selectedRequiredPurchaseOrderStates.length > 0 ||
			selectedSomePurchaseOrderStates.length > 0 ||
			selectedNotAllPurchaseOrderStates.length > 0 ||
			selectedFutureScheduledJobTypes.length > 0 ||
			selectedDisallowedScheduledJobTypes.length > 0 ||
			selectedRequiredEstimateStates.length > 0 ||
			selectedAnyLogStatuses.length > 0 ||
			selectedRequireEstimateBalance ||
			selectedRequiredBillingApproaching ||
			selectedRequiredProblemReason ||
			stageId
				? {
						phases: selectedPhases,
						jobTypes: selectedTypeFilters,
						assignedUserIds: selectedAssignees,
						lastLogTypes: selectedLogStatuses.length ? selectedLogStatuses : (logStatuses as JobLogType[]),
						anyLogTypes: selectedAnyLogStatuses,
						purchaseOrderStates: selectedRequiredPurchaseOrderStates,
						somePurchaseOrderStates: selectedSomePurchaseOrderStates,
						notAllPurchaseOrderStates: selectedNotAllPurchaseOrderStates,
						futureScheduledJobTypes: selectedFutureScheduledJobTypes,
						disallowedScheduledJobTypes: selectedDisallowedScheduledJobTypes,
						estimateStates: selectedRequiredEstimateStates,
						estimateRequiresBalance: selectedRequireEstimateBalance,
						requireBillingApproaching: selectedRequiredBillingApproaching,
						requireProblemReason: selectedRequiredProblemReason,
						stageId,
						branchIds: activeBranches.map((branchId) => Number(branchId)),
					}
				: undefined,
		sortBy,
		sortDirection,
		searchQuery: categoryJobSearch ?? undefined,
		businessCategory: activeCategory,
		limit: 25,
	});

	const { data: jobCountByUniqueFilters } = useInfinite('jobs', 'countByFilters', {
		filtersByJobSubView: {
			[JobSubView.ALL]: {
				phases: [JobPhase.ESTIMATE, JobPhase.WORK_ORDER],
				jobTypes: selectedTypeFilters,
				assignedUserIds: selectedAssignees,
				lastLogTypes: logStatuses as JobLogType[],
			},
			[JobSubView.ALL_OPPORTUNITIES]: {
				phases: [JobPhase.ESTIMATE],
				jobTypes: selectedTypeFilters,
				assignedUserIds: selectedAssignees,
				lastLogTypes: logStatuses as JobLogType[],
			},
			[JobSubView.ALL_WON_JOBS]: {
				phases: [JobPhase.WORK_ORDER],
				jobTypes: selectedTypeFilters,
				assignedUserIds: selectedAssignees,
				lastLogTypes: logStatuses as JobLogType[],
			},
			[JobSubView.FLAGGED]: {
				phases: [JobPhase.ESTIMATE, JobPhase.WORK_ORDER],
				jobTypes: selectedTypeFilters,
				assignedUserIds: selectedAssignees,
				lastLogTypes: logStatuses as JobLogType[],
				requireProblemReason: true,
			},
		},
		searchQuery: categoryJobSearch ?? undefined,
		businessCategory: activeCategory?.toUpperCase() as BusinessCategory,
		limit: 200,
	});

	// Commenting out until we get some more clarification as to what this is supposed to do.
	// const topAnalyticsQuery = trpc.analytics.getJobCategoryRevenueWithMOMChange.useQuery({
	// 	isCommercial: queryCommercial,
	// })

	// const topAnalyticsData = topAnalyticsQuery.data ?? [];

	const tableContext = {
		jobs: data as [BusinessCategory, FormattedJob[]][],
		jobQueryMeta: allJobsQuery,
		// topAnalyticsData,
		// topAnalyticsMeta: topAnalyticsQuery,
		fetchNextPage,
		isFetching: allJobsQuery.isFetching,
		categorySearch: [categoryJobSearch, setCategoryJobSearch] as ContextUseState<string>,
		activeSearch: !!categoryJobSearch || !!selectedTypeFilters || !!selectedAssignees || !!activePhases,
	};

	const JobIndexContextData = {
		businessCategory: activeCategory,
		customStages,
		customStageWarningMap,
		filterBarContext: FilterBarContent,
		jobCountBySubView: jobCountByUniqueFilters?.[0],
		shouldHideCustomStageTabs: !activeCategory,
		tableContext,
	} as JobIndexContextSchema;

	return <JobIndexContext.Provider value={JobIndexContextData}>{children}</JobIndexContext.Provider>;
};
